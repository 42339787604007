<template>
  <b-form ref="form" @submit.prevent="sendEmail">
    <b-form-input
      id="email"
      name="email"
      :value="customerDetails.email"
      hidden
    />
    <b-row>
      <b-col cols="12">
        <b-alert
          v-if="messageSent === true"
          variant="primary"
          class="p-1"
          show
          dismissible
          >Your feedback was sent successfully</b-alert
        >
      </b-col>
      <b-col md="6">
        <b-form-group label="First Name" label-for="first-name">
          <b-form-input
            id="first-name"
            name="first_name"
            :value="customerDetails.firstName"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Last Name" label-for="last-name">
          <b-form-input
            id="last-name"
            name="last_name"
            :value="customerDetails.secondName"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Which account does your message relate to?"
          label-for="premises"
        >
          <b-form-select
            v-model="selected"
            :options="options"
            size="md"
            id="premises"
            name="premises"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Please select a subject for your message?"
          label-for="subject"
        >
          <b-form-select
            v-model="selectedOption"
            :options="subjectOptions"
            size="md"
            id="subject"
            name="subject"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Your Message" label-for="feedback">
          <b-form-textarea
            id="feedback"
            name="feedback"
            placeholder="Enter your Message here and submit."
            rows="3"
            max-rows="6"
            v-model="message"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-form-textarea
        id="note-for-sales"
        name="note-for-sales"
        class="d-none"
        v-model="salesNote"
      ></b-form-textarea>
      <!-- submit and reset -->
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          id="submit-btn"
          :disabled="btnDisabled"
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormSelect,
  BForm,
  BButton,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { init } from "@emailjs/browser";
init("user_CMLr1sdGgc3k6ahku6o17");
import emailjs from "@emailjs/browser";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormSelect,
    BForm,
    BButton,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      message: "",
      salesNote: "",
      btnDisabled: false,
      customerDetails: JSON.parse(localStorage.getItem("userData")),
      messageSent: "pending",
      selected: [],
      options: [],
      selectedOption: "General Contact",
      subjectOptions: [
        { value: "General Contact", text: "General Contact" },
        { value: "Portal Feedback", text: "Portal Feedback" },
        { value: "Add New Service", text: "Add New Service" },
        { value: "Missed Collection", text: "Missed Collection" },
        {
          value: "Excess Weight Notification",
          text: "Excess Weight Notification",
        },
        { value: "Account Details Update", text: "Account Details Update" },
        { value: "Billing Query", text: "Billing Query" },
        { value: "Report an Issue", text: "Report an Issue" },
        { value: "Referral", text: "Refer A Friend" },
        { value: "Additional", text: "Add Another Business" }
      ],
      leadSource: null,
    };
  },
  mounted: function () {
    this.getPremises();
    this.getQueryParams();
  },
  methods: {
    sendEmail() {
      if (this.selectedOption == "Additional") {
        this.salesNote = "Customer should receive free delivery on their new order, as well as £15+VAT credit on their account.";
      }
      if (this.selectedOption == "Referral") {
        this.salesNote = "The new customer should receive free delivery on their order, and the referrer should receive £15+VAT credit on their account.";
      }

      setTimeout(() => {
        this.handleEmailJS();
      }, 350);
    },
    handleEmailJS() {
      var vm = this;
      vm.btnDisabled = true;
      document.getElementById("submit-btn").innerText = "Sending";
      emailjs
        .sendForm(
          "service_9oxp74r",
          "template_xy5hqpn",
          this.$refs.form,
          "user_CMLr1sdGgc3k6ahku6o17"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.messageSent = true;
            this.message = "";
            document.getElementById("submit-btn").innerText = "Submit";
            vm.btnDisabled = false;
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.messageSent = false;
            document.getElementById("submit-btn").innerText = "Submit";
            vm.btnDisabled = false;
          }
        );
    },
    getPremises() {
      const customerDetails = this.customerDetails;
      let arrayLength = customerDetails.AccIds.length;
      const premises = [];
      for (var i = 0; i < arrayLength; i++) {
        premises[i] = {
          value: customerDetails.AccIds[i].AccName,
          text: customerDetails.AccIds[i].AccName,
        };
      }
      this.selected = premises[0].value;

      this.options = premises;
      return premises;
    },
    getQueryParams() {
      let subject = this.$route.query.subject;
      let leadSource = this.$route.query.leadSource;

      this.handleSubjectParam(subject);
      this.handleLeadSourceParam(leadSource);
    },
    handleSubjectParam(subject) {
      if (!!subject) {
        this.selectedOption = subject;
      }
    },
    handleLeadSourceParam(leadSource) {
      if (!!leadSource) {
        this.leadSource = leadSource;
      }
    },
  },
};
</script>
